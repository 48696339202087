import React, {useCallback, useState} from 'react';
import {TablePagination} from '@mui/material';
import AdditionalCostIcon from '@mui/icons-material/PlaylistAdd';
import {ReactComponent as OneOffDecorationIcon} from '../../../assets/images/svgicons/colors.svg';
import {Column, Field, Fields, Row, Table, TableRow, usePageContext} from '../../../componentsLib/Layout';
import {SalesDoc, SalesDocItem} from '../Models/SalesDoc';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {ModelessPopover, Placement, ToolboxSection, useModelessPopoverState} from '../../../componentsLib/Popovers';
import {Button, CurrencyRateInput, HoverTools, ImageThumbnail, TextInput, ToolTip, WysiwygEditor, WysiwygText} from '../../../componentsLib/Basic';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {useFetchSageProducts, useGetSageLists, useSageSearch} from '../../../hooks/api';
import {MultiSelect, MultiSelectItem} from '../../../componentsLib/Pickers/MultiSelect';
import {registerGlobalStyle} from '../../../theme';
import {useLocalStorageState} from '../../../hooks';
import {SalesDocSections} from '../Components/SalesDocSection';
import {GridPricingSection, ImageSection, PresentationColorsAndSizesSection, ProductSettingsSection, RowPricingSection} from './ToolboxSections';
import {asCurrencyRateString, asCurrencyString, asNumber} from '../../../utils';

registerGlobalStyle('.sage-toolbox', (theme) => ({
  '&.sage-search-toolbox': {
    '.search-result-grid': {
      flexWrap: 'wrap',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(2),
    },
    '.search-result-tile': {
      border: `1px solid ${theme.colors.border.light}`,
      borderRadius: theme.shape.borderRadius,
      width: '160px',
      cursor: 'pointer',
      p: {
        width: '100%',
        textAlign: 'center',
        paddingInline: theme.spacing(.5),
      },
      '.title': {
        flexGrow: 1,
        lineHeight: '1.4',
        fontWeight: theme.typography.bold,
      },
      '&:hover': {borderColor: theme.colors.border.highlight},
    },
    '.image-thumbnail': {
      width: '160px',
      height: '160px'
    },
    '.MuiTablePagination-root': {
      padding: 0,
      border: 'none',
      '.MuiTablePagination-toolbar': {minHeight: 0},
      'button': {padding: 0},
    }
  },
  '&.sage-product-toolbox': {
    '.product-details': {
      paddingTop: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
    '.product-details, .product-specifics': {
      '.text': {lineHeight: 1.66},
      '.text-caption': {
        fontWeight: theme.typography.bold,
        fontSize: theme.fontSize(14),
        color: theme.colors.text.main,
      }
    },
    '.product-details + .text-wysiwyg': {paddingTop: theme.spacing(1)},
    '.fields.left-label>*:nth-child(even)': {overflow: 'visible'},
    '.sage-option-list': {
      display: 'inline',
      '&>p': {display: 'inline'},
      '&>p:first-child': {fontWeight: theme.typography.dark},
      '&:has(>.sage-cost-item:first-child) > .sage-cost-item > p:first-of-type': {fontWeight: theme.typography.dark},
      '&>* + *': {marginLeft: theme.spacing(.5)},
    },
    '.sage-cost-item': {
      position: 'relative',
      display: 'inline',
      borderBottom: `1px solid ${theme.colors.border.light}`,
      transition: theme.transitions.out.all,
      color: theme.colors.text.main,
      cursor: 'pointer',
      '&:hover': {
        borderColor: theme.colors.text.highlight,
        transition: theme.transitions.out.all,
        color: theme.colors.text.highlight,
        '&>.text': {
          display: 'inline',
          color: theme.colors.text.highlight,
          transition: theme.transitions.in.all,
        },
      },
      '&>.text': {
        display: 'inline',
        transition: theme.transitions.out.all,
      },
      '&:has(+.sage-cost-item)::after': {
        content: '";"',
        display: 'inline',
        lineHeight: '1.66',
      },
      '.hover-tools': {
        left: 0,
        top: theme.spacing(-4),
        'svg': {
          width: theme.spacing(3),
          height: theme.spacing(3),
        }
      },
    },
  },
}));

export function SageToolbox() {
  const {salesDoc, selection} = usePageContext();

  const firstItem = salesDoc.getFirstVariantItem(selection.variantId);

  return firstItem.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER ? <SageSearchToolbox/> : <SageProductToolbox/>;
}

function SageSearchToolbox() {
  const {salesDoc, selection, setSelection} = usePageContext();
  const {data: {categories, themes}, isLoading: itemsLoading} = useGetSageLists();
  const {query: sageSearchApi, isLoading: searchLoading} = useSageSearch();
  const [searchFields, setSearchFields] = useState({});
  const [searchResults, setSearchResults] = useState(null);
  const [_page, setPage] = useState(1);
  const [_pageSize, setPageSize] = useLocalStorageState('sageToolbox|pageSize', 24);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const [selectedProductIds, setSelectProductIds] = useState([]);
  const {query: fetchSageProductsApi, isLoading: productsLoading} = useFetchSageProducts();

  const handleCategoriesChange = useCallback((selectedCategories) => {
    setSearchFields((prev) => ({...prev, categories: selectedCategories}));
  }, []);

  const handleThemesChange = useCallback((selectedThemes) => {
    setSearchFields((prev) => ({...prev, themes: selectedThemes}));
  }, []);

  const handleFieldChange = useCallback((e) => {
    setSearchFields((prev) => {
      if (e.target.value) {
        return {...prev, [e.target.name]: e.target.value};
      } else {
        const newValue = {...prev};
        delete newValue[e.target.name];
        return newValue;
      }
    });
  }, []);

  const requestSearch = useCallback(async (page, pageSize = _pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    const results = await sageSearchApi({params: {...searchFields, pageSize, page}});
    if (results && results.products?.length) {
      setSearchResults(results.products);
      setSearchResultCount(results.total);
      setPage(page);
    }
  }, [_pageSize, sageSearchApi, searchFields, setPageSize]);

  const handleSearch = useCallback(async () => {
    await requestSearch(1);
  }, [requestSearch]);

  const handleSearchAgain = useCallback(() => {
    setSearchResults(null);
  }, []);

  const handleChangePage = useCallback(async (_, page) => {
    await requestSearch(page + 1);
  }, [requestSearch]);

  const handleChangeRowsPerPage = useCallback(async (e) => {
    await requestSearch(1, +e.target.value);
  }, [requestSearch]);

  const handleSelectProduct = useCallback((product) => {
    setSelectProductIds((prev) => {
      const index = prev.indexOf(product._id);
      if (index >= 0) {
        return prev.toSpliced(index, 1);
      } else {
        return [...prev, product._id];
      }
    });
  }, []);

  const items = salesDoc.getVariantItems(selection.variantId);
  const firstItem = items[0];
  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;

  const handleAddProducts = useCallback(async () => {
    const res = await fetchSageProductsApi({params: {ids: selectedProductIds}});
    const products = res?.products;
    if (products.length > 0) {
      const doc = firstItem.setProduct(products.shift()); // Pop the first product and set the product in the item
      if (doc.newItem) {
        setSelection({section: SalesDocSections.product, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
      }
      if (products.length > 0) {
        doc.addSageProducts(doc.getItem(firstItem.itemId), products);
      }
    }
  }, [fetchSageProductsApi, firstItem, selectedProductIds, setSelection]);

  return (
    <SalesDocToolbox
      className={'sage-toolbox sage-search-toolbox'}
      heading={'SAGE Search'}
      noBack={isPresentation && !searchResults}
      onBack={searchResults && handleSearchAgain}
    >
      {!searchResults &&
        <>
          <ToolboxSection heading={'Search Criteria'}>
            <Fields columns={2}>
              <Field>
                <MultiSelect label={'Category'} loading={itemsLoading} placeholder={'All Categories'} value={searchFields.categories} onChange={handleCategoriesChange}>
                  {categories?.map((category) => (
                    <MultiSelectItem key={category} value={category} text={category} checked={!!searchFields.categories?.includes(category)}></MultiSelectItem>
                  ))}
                </MultiSelect>
              </Field>
              <Field>
                <MultiSelect label={'Theme'} loading={itemsLoading} placeholder={'None selected'} value={searchFields.themes} onChange={handleThemesChange}>
                  {themes?.map((theme) => (
                    <MultiSelectItem key={theme} value={theme} text={theme} checked={!!searchFields.themes?.includes(theme)}></MultiSelectItem>
                  ))}
                </MultiSelect>
              </Field>
              <Field gridColumn={'1/3'}>
                <TextInput
                  label={'Keywords'}
                  placeholder={'Keywords (use AND and OR as needed)'}
                  name={'keywords'}
                  value={searchFields.keywords}
                  onChange={handleFieldChange}
                />
              </Field>
              <Field>
                <TextInput
                  label={'Item Number'}
                  placeholder={'Item #s or SAGE Product Codes (comma separated)'}
                  name={'itemNumbers'}
                  value={searchFields.itemNumbers}
                  onChange={handleFieldChange}
                />
              </Field>
              <Field>
                <TextInput label={'Item Name'} placeholder={'Item Name'} name={'itemName'} value={searchFields.itemName} onChange={handleFieldChange}/>
              </Field>
              <Field gridColumn={'1/3'}>
                <TextInput
                  label={'Colors'}
                  placeholder={'Color(s) (use AND and OR as needed)'}
                  name={'colors'}
                  value={searchFields.colors}
                  onChange={handleFieldChange}
                />
              </Field>
              <Field gridColumn={'1/3'}>
                <CaptionText text={'Price'}/>
                <Row gap>
                  <TextInput placeholder={'Min'} name={'priceMin'} value={searchFields.priceMin} onChange={handleFieldChange}/>
                  <TextInput placeholder={'Max'} name={'priceMax'} value={searchFields.priceMax} onChange={handleFieldChange}/>
                  <TextInput placeholder={'Qty'} name={'quantity'} value={searchFields.quantity} onChange={handleFieldChange}/>
                </Row>
              </Field>
              <Field gridColumn={'1/3'}>
                <TextInput
                  label={'Supplier'}
                  placeholder={'Company name, line name, SAGE# or Personal ID'}
                  name={'supplier'}
                  value={searchFields.supplier}
                  onChange={handleFieldChange}
                />
              </Field>
            </Fields>
          </ToolboxSection>
          <ToolboxSection stickyFooter>
            <Row justifyRight>
              <Button navMain text={'Start Search'} noWrap onClick={handleSearch} loading={searchLoading}/>
            </Row>
          </ToolboxSection>
        </>
      }
      {searchResults &&
        <>
          <ToolboxSection heading={'Search Results'}>
            {searchResults.length === 0 &&
              <BodyText>No products matched the search criteria</BodyText>
            }
            {searchResults.length > 0 &&
              <Row className={'search-result-grid'}>
                {searchResults?.map((prod) => (
                  <SageProductTile
                    key={prod._id}
                    product={prod}
                    quantity={searchFields.quantity}
                    selected={selectedProductIds.includes(prod._id)}
                    onSelectProduct={handleSelectProduct}
                  />
                ))}
              </Row>
            }
          </ToolboxSection>
          <ToolboxSection stickyFooter>
            <Row justifySpaceBetween>
              <Table>
                <tr>
                  <TablePagination
                    count={searchResultCount}
                    page={_page - 1}
                    rowsPerPage={_pageSize}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[24, 48, 100]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </tr>
              </Table>
              <Row gap>
                <Button
                  navMain
                  text={selectedProductIds.length ? `Add ${selectedProductIds.length} ${selectedProductIds.length === 1 ? 'Product' : 'Products'}` : 'Add Products'}
                  disabled={!selectedProductIds.length}
                  noWrap
                  loading={productsLoading}
                  onClick={handleAddProducts}
                />
                <Button navMain text={'Refine Search'} noWrap onClick={handleSearchAgain}/>
              </Row>
            </Row>
          </ToolboxSection>
        </>
      }
    </SalesDocToolbox>
  );
}

function SageProductToolbox() {
  const {salesDoc, selection} = usePageContext();

  const items = salesDoc.getVariantItems(selection.variantId);
  const firstItem = items[0];
  const product = firstItem.product;
  const extensions = product.extensions;
  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;
  const quantities = extensions?.qty.map((qty) => asNumber(qty));

  return (
    <SalesDocToolbox
      className={['sage-toolbox sage-product-toolbox']}
      heading={'SAGE Product'}
      noBack
    >
      {extensions &&
        <>
          <ToolboxSection heading={firstItem.name}>
            {firstItem.product.extensions &&
              <>
                <Fields className={'product-details'} columns={2} leftLabel>
                  <CaptionText text={'Item #'}/>
                  <BodyText text={extensions.itemNum}/>
                  <CaptionText text={'Supplier:'}/>
                  <BodyText text={`${firstItem.vendor.name} (SAGE ${extensions.suppId})`}/>
                  <CaptionText text={'SPC:'}/>
                  <BodyText text={extensions.spc}/>
                  <CaptionText text={'Line:'}/>
                  <BodyText text={extensions.lineName}/>
                  <CaptionText text={'Category:'}/>
                  <BodyText text={extensions.category}/>
                  <CaptionText text={'Price Code:'}/>
                  <BodyText text={extensions.priceCode}/>
                </Fields>
                <WysiwygText text={extensions.description}/>
              </>
            }
          </ToolboxSection>
          <ToolboxSection heading={'Description'} innerSep>
            <WysiwygEditor value={firstItem.description} onChange={firstItem.setDescription} />
          </ToolboxSection>
          {!isPresentation &&
            <RowPricingSection heading={'Variant Pricing'} items={items}/>
          }
          {isPresentation &&
            <GridPricingSection items={items} />
          }
          {isPresentation &&
            <PresentationColorsAndSizesSection item={firstItem}/>
          }
          <ImageSection item={firstItem}/>
          <ToolboxSection className={'product-specifics'} heading={'Product Specifics'} innerSep>
            <Fields leftLabel>
              <CaptionText text={'Colors'}/>
              <BodyText text={extensions.colors}/>
              <CaptionText text={'Themes'}/>
              <BodyText text={extensions.themes}/>
              <CaptionText text={'Decoration'}/>
              <BodyText text={textList('. ', [
                extensions.decorationMethod,
                extensions.imprintArea,
                extensions.secondImprintArea && `Second location: ${extensions.secondImprintArea}`,
                extensions.priceIncludes && extensions.priceIncludes !== 'Blank' && `Price includes ${extensions.priceIncludes.replaceAll(';', ', ')}`,
                extensions.priceIncludes && extensions.priceIncludes === 'Blank' && 'Decoration not included in price',
              ])}/>
              <CaptionText text={'Options'}/>
              <Column className={'sage-cost-options'}>
                {extensions.options.filter(({Name}) => !['Size', 'Size-Petite'].includes(Name)).map((option, index) => (
                  <SageOption key={index} option={option} quantities={quantities} />
                ))}
              </Column>
              <CaptionText text={'Product Compliance'}/>
              <BodyText text={extensions.productCompliance}/>
              <CaptionText text={'Production Time'}/>
              <BodyText text={extensions.prodTime}/>
              <CaptionText text={'Packaging'}/>
              <BodyText text={textList('; ', [
                extensions.package,
                extensions.unitsPerCarton && `${extensions.unitsPerCarton} units/ctn`,
                extensions.weightPerCarton && `${extensions.weightPerCarton} lbs./ctn`,
                (asNumber(extensions.cartonL) || asNumber(extensions.cartonW) || asNumber(extensions.cartonH))
                  && `Dimensions: ${textList('" x ', [asNumber(extensions.cartonL), asNumber(extensions.cartonW), asNumber(extensions.cartonH)])}"`
              ])}/>
              <CaptionText text={'Shipping'}/>
              <BodyText text={textList(' ', [
                (extensions.shipPointZip || extensions.shipPointCountry) && 'Ships from',
                extensions.shipPointZip,
                extensions.shipPointCountry,
              ])}/>
              <CaptionText text={'Origin'}/>
              <BodyText text={textList('; ', [
                extensions.madeInCountry && `Made in ${extensions.madeInCountry}`,
                extensions.assembledInCountry && `Assembled in ${extensions.assembledInCountry}`,
                extensions.decoratedInCountry && `Decorated in ${extensions.decoratedInCountry}`,
              ])}/>
              <CaptionText text={'Additional Charges'}/>
              <span className={'sage-option-list'}>
                {!!asNumber(extensions.setupChg) &&
                  <SageCost setupCost={extensions.setupChg} setupCode={extensions.setupChgCode} title={'Setup:'}/>
                }
                {!!asNumber(extensions.repeatSetupChg) &&
                  <SageCost setupCost={extensions.repeatSetupChg} setupCode={extensions.repeatSetupChgCode} title={'Repeat Setup:'}/>
                }
                {!!asNumber(extensions.screenChg) &&
                  <SageCost setupCost={extensions.screenChg} setupCode={extensions.screenChgCode} title={'Screen:'}/>
                }
                {!!asNumber(extensions.addClrChg) &&
                  <SageCost
                    title={'Additional Color/Location:'}
                    setupCost={extensions.addClrChg}
                    setupCode={extensions.addClrChgCode}
                    prices={extensions.addClrRunChg}
                    priceCode={extensions.addClrRunChgCode}
                    quantities={quantities}
                  />
                }
                {!!asNumber(extensions.plateChg) &&
                  <SageCost setupCost={extensions.plateChg} setupCode={extensions.plateChgCode} title={'Plate:'}/>
                }
                {!!asNumber(extensions.dieChg) &&
                  <SageCost setupCost={extensions.dieChg} setupCode={extensions.dieChgCode} title={'Die:'}/>
                }
                {!!asNumber(extensions.toolingChg) &&
                  <SageCost setupCost={extensions.toolingChg} setupCode={extensions.toolingChgCode} title={'Tooling:'}/>
                }
              </span>
              <CaptionText text={'Catalog'}/>
              <BodyText text={textList(' ', [
                extensions.catYear,
                extensions.catPage && `pg. ${extensions.catPage}`
              ])}/>
              <CaptionText text={'Keywords'}/>
              <BodyText text={extensions.keywords}/>
              <CaptionText text={'Comments'}/>
              <BodyText text={extensions.comment}/>
            </Fields>
          </ToolboxSection>
          <ProductSettingsSection item={firstItem} />
        </>
      }
    </SalesDocToolbox>
  );
}

function SageProductTile({product, quantity, selected, onSelectProduct}) {
  const handleSelectProduct = useCallback(() => {
    onSelectProduct?.(product);
  }, [onSelectProduct, product]);

  return (
    <Column className={'search-result-tile'} onClick={handleSelectProduct}>
      <ImageThumbnail imageUrl={product.image} selected={selected}/>
      <BodyText className={'title'} text={product.title} />
      <BodyText
        className={'price'}
        text={
          quantity && !product.buyPrice.includes('-')
            ? `${quantity} @ $${product.buyPrice}`
            : product.buyPrice.split('-').map((value) => `$${value.trim()}`).join(' - ')
        }
      />
    </Column>
  );
}

function SageOption({option, quantities}) {
  return (
    <span className={'sage-option-list'}>
      <BodyText text={`${option.Name}:`}/>
      {option.Values.map(({Value, Net}) => (
        <SageCost key={Value} title={Value} prices={Net} quantities={quantities}/>
      ))}
      {option.PriceCode &&
        <BodyText text={`(${option.PriceCode})`}/>
      }
    </span>
  );
}

function SageCost({title, setupCost, setupCode, prices, priceCode, quantities}) {
  const {salesDoc, selection} = usePageContext();
  const [popupType, setPopupType] = useState(null);
  const {open, anchorEl, anchorElRef, closePopover, openPopover} = useModelessPopoverState();
  const items = salesDoc.getVariantItems(selection.variantId);
  const firstItem = items[0];

  prices = prices?.filter((price, index) => asNumber(price) && asNumber(quantities[index])) ?? [];
  if (prices.length > 1 && prices.every((price) => price === prices[0])) {
    prices = [prices[0]];
  }

  let priceBreaks = items.map((item) => ({
    quantity: item.quantity,
    price: prices.findLast((_, index) => item.quantity >= quantities[index]) ?? prices[0],
  }));

  const hasSetupCost = !!asNumber(setupCost);

  const addDecoration = useCallback(() => {
    setPopupType('decoration');
    openPopover();
  }, [openPopover]);

  const addAdditionalCost = useCallback(() => {
    setPopupType('additional-cost');
    openPopover();
  }, [openPopover]);

  const handleAddItem = useCallback(() => {
    if (popupType === 'decoration') {
      firstItem.addOneOffDecoration({
        name: title,
        vendorId: firstItem.vendorId,
        priceBreaks: prices.length > 1 ? priceBreaks : undefined,
        buyPrice: prices.length === 1 ? prices[0] : undefined,
        setupCost: hasSetupCost ? asNumber(setupCost) : undefined,
      });
    } else if (popupType === 'additional-cost') {
      firstItem.addAdditionalCost({
        description: title,
        vendorId: firstItem.vendorId,
        priceBreaks: prices.length > 1 ? priceBreaks : undefined,
        buyPrice: hasSetupCost ? asNumber(setupCost) : prices[0],
      });
    }
  }, [firstItem, hasSetupCost, popupType, priceBreaks, prices, setupCost, title]);

  return (
    <Row
      className={'sage-cost-item hover-tools-container'}
      onClick={prices.length && !/setup/i.test(title) ? addDecoration : addAdditionalCost}
      ref={anchorElRef}
    >
      <HoverTools>
        <ToolTip tip={'Add cost as decoration'} top>
          <OneOffDecorationIcon onClick={addDecoration} />
        </ToolTip>
        {(!hasSetupCost || !prices.length) &&
          <ToolTip tip={'Add cost as additional cost'} top>
            <AdditionalCostIcon onClick={addAdditionalCost} />
          </ToolTip>
        }
      </HoverTools>
      <BodyText className={'sage-cost-title'} text={title}/>
      {hasSetupCost &&
        <>
          {prices &&
            <BodyText text={' Setup'}/>
          }
          <BodyText text={` $${asCurrencyString(setupCost)}`}/>
          {setupCode &&
            <BodyText text={` (${setupCode})`}/>
          }
        </>
      }
      {hasSetupCost && prices.length > 0 &&
        <BodyText text={' Running Charge'}/>
      }
      {prices.length === 1 &&
        <BodyText>
          {` $${asCurrencyRateString(prices[0])}`}
        </BodyText>
      }
      {prices.length > 1 &&
        <BodyText>
          {prices.map((price, index) => ` ${quantities[index]}:+$${asCurrencyRateString(price)}`).join(',')}
        </BodyText>
      }
      {priceCode &&
        <BodyText text={` (${priceCode})`}/>
      }
      <ModelessPopover dialog anchorEl={anchorEl} open={open} placement={Placement.BottomRight} onClose={closePopover}>
        <HeadingText x16 text={popupType === 'additional-cost' ? 'Add Additional Cost' : 'Add Decoration'}/>
        <Fields topLabel>
          <CaptionText text={'Name'}/>
          <TextInput value={title} readonly/>
          {hasSetupCost &&
            <CurrencyRateInput label={popupType === 'additional-cost' ? 'Flat rate' : 'Setup Cost'} value={setupCost} readonly/>
          }
          {prices.length === 1 &&
            <CurrencyRateInput label={'Flat rate'} value={prices[0]} readonly/>
          }
          {prices.length > 1 &&
            <>
              <CaptionText text={'Variant pricing'}/>
              <Table bodyBorder cellPadding>
                <TableRow>
                  <BodyText text={'Qty'}/>
                  {priceBreaks.map((pb) => (
                    <BodyText key={pb.quantity} text={pb.quantity}/>
                  ))}
                </TableRow>
                <TableRow>
                  <BodyText text={'Rate/Unit'}/>
                  {priceBreaks.map((pb) => (
                    <BodyText key={pb.quantity} text={pb.price} currency/>
                  ))}
                </TableRow>
              </Table>
            </>
          }
        </Fields>
        <Row>
          <Button navNegative onClick={closePopover}>Cancel</Button>
          <Button navPositive onClick={handleAddItem}>Add</Button>
        </Row>
      </ModelessPopover>
    </Row>
  );
}

function textList(join, texts) {
  return texts.filter(Boolean).join(join);
}
