import React, {useCallback, useMemo, useState} from 'react';
import {Delete as DeleteIcon, Edit as EditIcon, FileCopy as CopyIcon} from '@mui/icons-material';
import {Column, Field, Row, usePageContext} from '../../../componentsLib/Layout';
import {useDeleteSalesDoc, useListSalesDocs, useSaveSalesDoc} from '../../../hooks/api';
import {SalesDoc} from '../Models/SalesDoc';
import {ModelessPopover, PopupItem, ToolboxSection, useModelessPopoverState} from '../../../componentsLib/Popovers';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {Button, Radio, Switch, TextInput, WysiwygProvider, WysiwygSubstitution} from '../../../componentsLib/Basic';
import {PermissionBasedContent, permissionCheck, SubscriptionTypes, TaxSelect} from '../../../componentsHoops';
import {Select, SelectItem} from '../../../componentsLib/Pickers';
import {WarningIcon} from '../../../componentsLib/Icons';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {registerGlobalStyle} from '../../../theme';
import {
  AdditionalCostSettingsSection,
  CustomerActionsSection,
  DecorationSettingsSection,
  DocumentTermsSection,
  PresentationBlockSection,
  ProductSettingsSection,
} from './ToolboxSections';
import {byLocaleCaseInsensitive, valueFromEvent} from '../../../utils';
import {additionalCostSubstitutions, decorationSubstitutions, productSubstitutions} from '../../../models/TemplateSubstitutions';

registerGlobalStyle('.settings-toolboxes', (theme) => ({
  '.template-name-description': {
    '.column': {flex: '0 0 50%',},
    '.template-description-field': {
      paddingTop: theme.spacing(1),
      '.text-input': {height: '4rem',},
    },
  },
  '&.choose-template-toolbox': {
    '.template-item': {
      position: 'relative',
      cursor: 'pointer',
      border: `1px solid ${theme.colors.border.light}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 2),
      transition: theme.transitions.out.all,
      '.text-caption': {fontSize: '.875rem'},
      '&:hover': {
        borderColor: theme.colors.border.main,
        transition: theme.transitions.in.all,
      },
      '&.selected': {
        borderColor: theme.colors.border.highlight,
        backgroundColor: theme.colors.background.contrast.main,
        transition: theme.transitions.in.all,
        '.radio-button': {
          color: theme.colors.text.contrast,
          transition: theme.transitions.in.all,
        },
        '.text': {
          color: theme.colors.text.contrast,
          transition: theme.transitions.in.all,
        }
      },
      '.radio-button': {
        position: 'absolute',
        top: theme.spacing(.5),
        right: theme.spacing(.5),
        color: theme.colors.text.medium,
        transition: theme.transitions.out.all,
      },
      '.hover-tools': {
        bottom: theme.spacing(-1),
        right: theme.spacing(3),
        svg: {fontSize: '1rem'},
      },
      '.text': {transition: theme.transitions.out.all,}
    },
    '.template-buttons': {justifyContent: 'space-between',},
  },
  '&.template-settings-toolbox': {'.pricing-settings': {'.basic-field': {width: '50%'},}},
}));

export function ChooseTemplateToolbox() {
  const {createSalesDoc, salesDoc: _salesDoc, setSelection} = usePageContext();
  const {delete: deleteSalesDoc} = useDeleteSalesDoc();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [deletingTemplate, setDeletingTemplate] = useState();
  const templateType = _salesDoc.documentType === SalesDoc.Type.PRESENTATION ? SalesDoc.Type.PRESENTATION_TEMPLATE : SalesDoc.Type.TEMPLATE;
  const {data: {salesDocs: _salesDocs}, refetch: refetchSalesDocs} = useListSalesDocs({query: {documentType: templateType}});
  const {open, anchorEl, anchorElRef, closePopover, togglePopover} = useModelessPopoverState();

  const handleEdit = useCallback((e, template) => {
    e.stopPropagation();
    createSalesDoc(template);
    setSelection((prev) => ({...prev, showSettings: true}));
  }, [createSalesDoc, setSelection]);

  const handleCopy = useCallback((e, template) => {
    e.stopPropagation();
    createSalesDoc({...template, _id: null, docTemplateName: `Copy of ${template.docTemplateName}`});
    setSelection((prev) => ({...prev, showSettings: true}));
  }, [createSalesDoc, setSelection]);

  const handleShowConfirmDelete = useCallback((e, template) => {
    e.stopPropagation();
    setDeletingTemplate(template);
    anchorElRef.current = e.target.closest('.hover-tools');
    togglePopover();
  }, [anchorElRef, togglePopover]);

  const handleDelete = useCallback(async () => {
    closePopover();
    await deleteSalesDoc({id: deletingTemplate._id});
    await refetchSalesDocs();
  }, [closePopover, deleteSalesDoc, deletingTemplate?._id, refetchSalesDocs]);

  const handleSelectTemplate = useCallback((e, template) => {
    setSelectedTemplate(template);
    if (e.detail >= 2) {
      createSalesDoc(template);
    }
  }, [createSalesDoc]);

  const handleAddNew = useCallback(() => {
    createSalesDoc({
      _id: null,
      docTemplateName: 'New Template',
      documentType: _salesDoc.documentType,
      docTypeName: permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) ? 'Quote' : ''
    });
    setSelection((prev) => ({...prev, showSettings: true}));
  }, [_salesDoc?.documentType, createSalesDoc, setSelection]);

  const handleUseSelected = useCallback(() => {
    createSalesDoc(selectedTemplate);
  }, [createSalesDoc, selectedTemplate]);

  const salesDocs = useMemo(() => _salesDocs?.toSorted((a, b) =>
    byLocaleCaseInsensitive(a.docTypeName, b.docTypeName) || byLocaleCaseInsensitive(a.docTemplateName, b.docTemplateName)), [_salesDocs]);

  return (
    <SalesDocToolbox className={'settings-toolboxes choose-template-toolbox'} heading={'Choose a Template'} noSettings noBack>
      <ToolboxSection>
        <Column gap>
          {salesDocs?.map((salesDoc) => (
            <Column
              key={salesDoc._id}
              className={['template-item hover-tools-container', selectedTemplate?._id === salesDoc._id && 'selected']}
              onClick={(e) => handleSelectTemplate(e, salesDoc)}
            >
              <BodyText>{salesDoc.docTypeName}</BodyText>
              <Row alignBaseline>
                <CaptionText>Template Name:</CaptionText>
                <BodyText>{salesDoc.docTemplateName}</BodyText>
              </Row>
              <CaptionText>
                {salesDoc.docTypeDescription}
              </CaptionText>
              <Radio checked={selectedTemplate === salesDoc} />
              <Row className={'hover-tools'}>
                <EditIcon onClick={(e) => handleEdit(e, salesDoc)} />
                <CopyIcon onClick={(e) => handleCopy(e, salesDoc)} />
                <DeleteIcon onClick={(e) => handleShowConfirmDelete(e, salesDoc)} />
              </Row>
            </Column>
          ))}
          <ModelessPopover dialog anchorEl={anchorEl} open={open} placement={'top-end'} onClose={closePopover}>
            Are you sure you want to delete {deletingTemplate?.docTemplateName}.<br />
            This cannot be undone.
            <Row>
              <Button navNegative onClick={closePopover}>Cancel</Button>
              <Button navPositive onClick={handleDelete}>Delete</Button>
            </Row>
          </ModelessPopover>
        </Column>
      </ToolboxSection>
      <ToolboxSection stickyFooter>
        <Row className={'template-buttons'}>
          <Button navPrimary onClick={handleAddNew}>Create a New Template</Button>
          <Button navMain onClick={handleUseSelected} disabled={!selectedTemplate}>Use Template</Button>
        </Row>
      </ToolboxSection>
    </SalesDocToolbox>
  );
}

export function SettingsToolbox() {
  const {company, salesDoc, setSelection} = usePageContext();
  const {save: saveSalesDocApi, isSaving: salesDocSaving} = useSaveSalesDoc();
  const [saveAs, setSaveAs] = useState(false);

  const setProductDescription = useCallback((newDescription) => {
    salesDoc.setTemplateProductDescription(newDescription);
  }, [salesDoc]);

  const setDecorationDescription = useCallback((newDescription) => {
    salesDoc.setTemplateDecorationDescription(newDescription);
  }, [salesDoc]);

  const setAdditionalCostDescription = useCallback((newDescription) => {
    salesDoc.setTemplateAdditionalCostDescription(newDescription);
  }, [salesDoc]);

  const setRounding = useCallback((e) => {
    salesDoc.setRounding(Number.parseInt(valueFromEvent(e)));
  }, [salesDoc]);

  const _handleSave = useCallback(async (id) => {
    const res = await saveSalesDocApi({id, salesDoc: salesDoc.forTemplate()}, {
      successMessage: `Saved document template "${salesDoc.docTemplateName}"`,
      errorMessage: ({message}) => {
        if (message?.includes?.('duplicate key error')) {
          return `Error saving template "${salesDoc.docTemplateName}": the name must be unique`;
        }
        return null;
      }
    });
    if (res) {
      salesDoc.setDocTemplateId(res.salesDoc._id);
      setSelection((prev) => ({...prev, showSettings: true}));
    }
  }, [salesDoc, saveSalesDocApi, setSelection]);

  const handleSave = useCallback(async () => {
    await _handleSave(salesDoc.docTemplateId);
  }, [_handleSave, salesDoc.docTemplateId]);

  const handleSaveNew = useCallback(async () => {
    await _handleSave(null);
  }, [_handleSave]);

  const handleSaveAs = useCallback(() => {
    setSaveAs(true);
  }, []);

  const handleSaveAsBack = useCallback(() => {
    setSaveAs(false);
  }, []);

  const handleExitSettings = useCallback(() => {
    if (salesDoc.docTemplateId == null) {
      salesDoc.setDocTemplateName(null);
    } else {
      setSelection((prev) => ({...prev, showSettings: false}));
    }
  }, [salesDoc, setSelection]);

  const allowedDecimals = company?.accountingPlatform?.platform === 'XERO' ? [2, 3, 4] : [2, 3, 4, 5, 6];

  if (saveAs) {
    return (
      <SalesDocToolbox className={'settings-toolboxes template-settings-toolbox'} heading={'Save a New Template'} noSettings onBack={handleSaveAsBack}>
        <SettingsToolboxNameAndDescriptionSection noHeading />
        <ToolboxSection innerSep>
          <Button
            text={'Save Template'}
            noWrap
            navPrimary
            onClick={handleSaveNew}
            disabled={!salesDoc.docTypeName || !salesDoc.docTemplateName}
            loading={salesDocSaving}
          />
        </ToolboxSection>
      </SalesDocToolbox>
    );
  } else {
    return (
      <SalesDocToolbox className={'settings-toolboxes template-settings-toolbox'} heading={'General Settings'} noSettings onBack={handleExitSettings}>
        <SettingsToolboxNameAndDescriptionSection />
        {salesDoc.isPresentation() &&
          <>
            <PresentationBlockSection
              section={'header'}
              image={salesDoc.template.headerImage}
              inSettings
              onImageChange={salesDoc.setTemplateHeaderBlockImage}
              onTextChange={salesDoc.setHeaderBlockText}
              showBlock={salesDoc.template.showHeaderBlock}
              setShowBlock={salesDoc.setTemplateShowHeaderBlock}
              text={salesDoc.template.headerText}
            />
            <PresentationBlockSection
              section={'title'}
              image={salesDoc.template.titleImage}
              inSettings
              onImageChange={salesDoc.setTemplateTitleBlockImage}
              onTextChange={salesDoc.setTitleBlockText}
              showBlock={salesDoc.template.showTitleBlock}
              setShowBlock={salesDoc.setTemplateShowTitleBlock}
              text={salesDoc.template.titleText}
            />
            <PresentationBlockSection
              section={'footer'}
              image={salesDoc.template.footerImage}
              inSettings
              onImageChange={salesDoc.setTemplateFooterBlockImage}
              onTextChange={salesDoc.setFooterBlockText}
              showBlock={salesDoc.template.showFooterBlock}
              setShowBlock={salesDoc.setTemplateShowFooterBlock}
              text={salesDoc.template.footerText}
            />
          </>
        }
        <CustomerActionsSection inSettings />
        {!salesDoc.isPresentation() &&
          <DocumentTermsSection />
        }
        {!salesDoc.isPresentation() &&
          <ToolboxSection className={'document-details'} heading={'Document Details'} hideShow storageKey={'salesdoc|toolbox|general|documentDetails'}>
            <Switch label={'Show Reference to Customer'} checked={salesDoc.template.showReference} onChange={salesDoc.setTemplateShowReference} />
            <Switch label={'Show Deadline to Customer'} checked={salesDoc.template.showDeadline} onChange={salesDoc.setTemplateShowDeadline} />
          </ToolboxSection>
        }
        <ToolboxSection className={'pricing-settings'} heading={'Pricing Settings'} hideShow storageKey={'salesdoc|toolbox|general|pricingSettings'}>
          {!salesDoc.isPresentation() &&
            <Switch label={'Hide Totals'} checked={salesDoc.template.hideTotals} onChange={salesDoc.setTemplateHideTotals} />
          }
          <Field>
            <TaxSelect label='Tax' value={salesDoc.template.tax} onChange={salesDoc.setTemplateTax} />
          </Field>
          <Field>
            <Select label='Round Unit Price and Sell Price to' value={salesDoc.getRounding().toString()} onChange={setRounding}>
              {allowedDecimals.map((v) => (
                <SelectItem key={v} value={v.toString()}>
                  {v === 2 ? 'The nearest cent' : `${v} decimal places`}
                </SelectItem>
              ))}
            </Select>
          </Field>
          <ProductSettingsSection />
          <DecorationSettingsSection />
          <AdditionalCostSettingsSection />
        </ToolboxSection>

        <ToolboxSection heading={'Description Settings'} hideShow storageKey={'salesdoc|toolbox|general|description'}>
          <ToolboxSection heading={'Catalog Product Description Settings'} innerSep>
            <WysiwygProvider>
              <WysiwygSubstitution substitutions={productSubstitutions} value={salesDoc.template.productDescription}
                onChange={setProductDescription}>
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Catalog Product. You can insert
                  these into the settings in any order to
                  completely customise your automated product descriptions.
                  <br />
                  If the chosen attribute is empty for the product, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
          <ToolboxSection heading={'Catalog Decoration Description Settings'} innerSep>
            <WysiwygProvider>
              <WysiwygSubstitution substitutions={decorationSubstitutions}
                value={salesDoc.template.decorationDescription} onChange={setDecorationDescription}>
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Catalog Decoration. You can insert
                  these into the settings in any order
                  to completely customise your automated descriptions.
                  <br />
                  If the chosen attribute is empty for the decoration, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
          <ToolboxSection heading={'Additional Cost Description Settings'} innerSep>
            <WysiwygProvider>
              <WysiwygSubstitution
                substitutions={additionalCostSubstitutions}
                value={salesDoc.template.additionalCostDescription}
                onChange={setAdditionalCostDescription}
              >
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Additional Cost. You can insert
                  these into the settings in any order to
                  completely customise your automated descriptions.
                  <br />
                  If the chosen attribute is empty for the additional cost, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
        </ToolboxSection>
        <ToolboxSection className={'save-template-section'} stickyFooter>
          <Row alignCenter gap>
            <WarningIcon />
            <BodyText>
              Updating the Pricing and Description settings will not update any items already on this document. Any new
              items added to this document will match the new settings.
            </BodyText>
            <Button text={'Save Template'} noWrap menu navPrimary>
              {salesDoc.docTypeName && salesDoc.docTemplateName && salesDoc.docTemplateId &&
                <PopupItem onClick={handleSave}>Update this Template</PopupItem>
              }
              <PopupItem onClick={handleSaveAs}>As a new Template</PopupItem>
            </Button>
          </Row>
        </ToolboxSection>
      </SalesDocToolbox>
    );
  }
}

function SettingsToolboxNameAndDescriptionSection({noHeading}) {
  const {salesDoc} = usePageContext();

  return (
    <ToolboxSection className={'template-name-description'} heading={noHeading ? undefined : 'Template Details'} hideShow={!noHeading} storageKey={'salesdoc|toolbox|general|documentType'}>
      <Row gap>
        <Column>
          <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
            <TextInput label={'Document Type'} value={salesDoc.docTypeName} onChange={salesDoc.setDocTypeName}></TextInput>
            <CaptionText>
              The Document Type is shown to your customer. This does not need to be unique.
            </CaptionText>
            <BodyText>
              Eg: Quote / Invoice / Estimate
            </BodyText>
          </PermissionBasedContent>
          <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
            <TextInput label={'Document Type'} value={salesDoc.docTypeName} disabled />
            <CaptionText>
              The Document Type is shown to your customer.
            </CaptionText>
          </PermissionBasedContent>
        </Column>
        <Column>
          <TextInput label={'Template Name'} value={salesDoc.docTemplateName} onChange={salesDoc.setDocTemplateName}></TextInput>
          <CaptionText>
            The Template Name will give you a unique identifier for this template. The template name must be unique and is not shown to your customer.
          </CaptionText>
          <BodyText>
            Eg: Quote - Averaged Pricing / Quote - No Accept Button
          </BodyText>
        </Column>
      </Row>
      <Column className={'template-description-field'}>
        <TextInput multiline label={'Description'} value={salesDoc.docTypeDescription} onChange={salesDoc.setDocTypeDescription} />
        <CaptionText>Use this optional field for internal notes about the use of this template.</CaptionText>
      </Column>
    </ToolboxSection>
  );
}
