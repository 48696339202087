import React, {useCallback, useMemo, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import CopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NewIcon from '@mui/icons-material/NewReleases';
import {FavoriteBorder as LikeIcon} from '@mui/icons-material';
import {kebabCase, uniq} from 'lodash';
import {Grid, Column, Field, FieldColumns, Fields, Row, Table, TableCell, TableHeading, TableHeadingCell, TableRow, usePageContext} from '../../../componentsLib/Layout';
import {Placement, ToolboxSection} from '../../../componentsLib/Popovers';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {
  Button,
  ButtonTheme,
  CurrencyInput,
  CurrencyRateInput,
  DescriptiveGroup,
  DescriptiveRadio,
  DescriptiveRadioGroup,
  DescriptiveSwitch,
  ImageThumbnail,
  InfoTip,
  IntegerInput,
  Link,
  PercentageInput,
  Switch,
  TextAutoComplete,
  TextInput,
  ToolTip,
  WysiwygEditor,
  WysiwygProvider,
  WysiwygSubstitution
} from '../../../componentsLib/Basic';
import {ColorPicker, DatePicker, Select, SelectItem, UserSelect} from '../../../componentsLib/Pickers';
import {SalesDoc, SalesDocItem} from '../Models/SalesDoc';
import {registerGlobalStyle} from '../../../theme';
import {ContactAutoComplete, FeatureFlag, TaxSelect} from '../../../componentsHoops';
import {
  asCurrencyString,
  asCurrencyStringCommaSeparated,
  asPercentageString,
  checkedFromEvent,
  formatAddressOneLine,
  formatDateShort,
  marginFromMarkup,
  MaxFileSize,
  sortSizes
} from '../../../utils';
import {SalesDocSections, SalesDocSubSections} from '../Components/SalesDocSection';
import {Chip} from '../../../componentsLib/Chips';
import {useCompany, useUser} from '../../../hooks';
import {useListUsers} from '../../../hooks/api';
import {ImageUploadManager} from '../../../componentsLib/DragDrop';
import {companySubstitutions, nestSubstitutions, salesDocSubstitutions} from '../../../models/TemplateSubstitutions';
import {featureFlags} from '../../../utils/featureFlag';
import {Collapse} from '@mui/material';
import {CurrencyRatePicker} from '../../../componentsLib/Pickers/CurrencyRatePicker';
import {ChipList} from '../../../componentsLib/Chips/ChipList';

registerGlobalStyle('.salesdoc-toolbox-sections', (theme) => ({
  '&.customer-actions.toolbox-section': {
    '.box-grid': {
      'alignItems': 'center',
      'a': {justifySelf: 'end',}
    },
    '.text-caption': {margin: theme.spacing(0, 0, -1.25),},
    '.indicator-chip': {backgroundColor: theme.colors.palette.orange,},
    '.button-theme': {'.icon': {padding: theme.spacing(1)}}
  },
  '&.decoration-settings:is(.presentation .decoration-settings) label': {flex: '1 1 0'},
  '&.additional-cost-settings:is(.presentation .additional-cost-settings) label': {flex: '1 1 0'},
  '&.row-pricing': {
    'table + .row': {
      alignItems: 'center',
      gap: '3rem',
      '.text-body': {fontSize: '.75rem'}
    },
  },
  '&.grid-pricing': {
    'table': {
      'td': {
        padding: 0,
        '.text-input input': {paddingLeft: theme.spacing(.5)},
        '&:first-child': {
          backgroundColor: theme.colors.background.empty,
          padding: theme.spacing(0.125, 1, 0),
          textWrap: 'nowrap',
        },
      },
      alignSelf: 'start',
      'tr:first-child td': {
        padding: 0,
        position: 'relative',
        width: 120,

        '.column-hover-tools': {
          position: 'absolute',
          width: '100%',
          left: theme.spacing(1),
          top: theme.spacing(-2.5),
          height: theme.spacing(2.5),
          zIndex: 1,
          'svg': {
            position: 'absolute',
            cursor: 'pointer',
            fontSize: 'calc(1rem - 1px)',
            color: theme.colors.text.highlight,
            opacity: 0,
            transition: theme.transitions.out.all,
          },
          '.delete-icon': {left: 'calc(50% + 1px - 1rem)',},
          '.add-icon': {right: 0,}
        },
        '&:hover svg, &.hovered svg': {
          opacity: 1,
          transition: theme.transitions.in.all,
        },
        '&:has(+ td:hover) .add-icon, &:has(+ td.hovered) .add-icon': {
          opacity: 1,
          transition: theme.transitions.in.all,
        },
      },
      '.currency-prefix::before': {
        left: theme.spacing(-1.375),
        minWidth: theme.spacing(1.5),
        marginRight: theme.spacing(-1.5),
      },
    },
    '&.flat-rate': {
      '&.type-decoration': {
        'tr:nth-child(n+2) td:nth-child(n+3)': {
          backgroundColor: theme.colors.background.disabled,
          '*, *::before, *::after': {color: theme.colors.text.mediumDecorator}
        },
      },
      '&.type-additional-cost': {
        'tr:not(:first-child):not(:last-child) td:nth-child(n+3)': {
          backgroundColor: theme.colors.background.disabled,
          '*, *::before, *::after': {color: theme.colors.text.mediumDecorator},
        }
      }
    },
    '.setup-cost button.disabled': {
      opacity: 1,
      textDecoration: 'none',
    },
    '.info-notice': {marginBlock: theme.spacing(0, 3)},
  },
  '&.company-section': {
    '.company-logo': {
      maxHeight: 200,
      alignItems: 'end',
      flex: '1 1 0',
      '.image-thumbnail': {
        maxWidth: 200,
        backgroundPositionX: 'right',
      },
    },
    '.fields': {
      padding: theme.spacing(2, 0, 1),
      columnGap: theme.spacing(4),
      '.text': {lineHeight: 1},
    },
  },
  '&.presentation-block': {
    '.text-heading, .text-caption': {textTransform: 'capitalize'},
    '.presentation-image-section, .presentation-text-section': {'.row': {'.text-heading': {fontSize: 14},}},
    '.presentation-text-section': {'.wysiwyg-input': {'.wysiwyg-text-input>*:first-child': {minHeight: 246}},},
  },
  '&.product-variants-section .chip-list': {padding: theme.spacing(.5, 0, 1)},
  'button.select-all': {margin: theme.spacing(0, .5, 0, 4)},
  'button.deselect-all': {margin: theme.spacing(0, .5, 0, .5)},
  'table td': {
    '.text-input': {
      '&.under-minimum, &.averaged-price': {
        backgroundColor: theme.colors.palette.redMildest,
        boxShadow: 'inset 0 0 0 2px white',
      },
      '&.clearable': {
        backgroundColor: theme.colors.palette.orangeMild,
        boxShadow: 'inset 0 0 0 2px white',
      },
    },
  },
}));

export function CustomerActionsSection({inSettings}) {
  const {salesDoc, company} = usePageContext();

  const hasStripe = !!company.stripeConnectedAccountId;

  return (
    <ToolboxSection
      className={'salesdoc-toolbox-sections customer-actions'}
      heading={inSettings && 'Customer Actions'}
      storageKey={inSettings && 'salesdoc|toolbox|general|customerActions'}
      hideShow={inSettings}
    >
      <Grid gridTemplateColumns={'auto auto auto auto auto'}>
        {!salesDoc.isPresentation() &&
          <>
            <CaptionText />
            <CaptionText text={'Enable'} />
            <CaptionText text={'Color'} />
            <CaptionText text={'Button Text'} />
            <CaptionText />
            <ButtonTheme color={salesDoc.template.pdfButtonColor}>
              <Button navMinor prefix={DescriptionOutlinedIcon} text={salesDoc.template.pdfButtonText} caps={false} />
            </ButtonTheme>
            <Switch checked={salesDoc.template.pdfButtonEnabled} onChange={salesDoc.setTemplatePdfButtonEnabled} />
            <ColorPicker color={salesDoc.template.pdfButtonColor} onChange={salesDoc.setTemplatePdfButtonColor} />
            <TextInput value={salesDoc.template.pdfButtonText} onChange={salesDoc.setTemplatePdfButtonText} />
            <div />
          </>
        }
        {salesDoc.isPresentation() &&
          <>
            <FeatureFlag allow={featureFlags.likeProduct}>
              <CaptionText />
              <CaptionText text={'Enable'} />
              <CaptionText text={'Color'} />
              <CaptionText />
              <CaptionText />
              <ButtonTheme color={salesDoc.template.likeButtonColor}>
                <Button navStandard prefix={LikeIcon} />
              </ButtonTheme>
              <Switch checked={salesDoc.template.likeButtonEnabled} onChange={salesDoc.setTemplateLikeButtonEnabled} />
              <ColorPicker color={salesDoc.template.likeButtonColor} onChange={salesDoc.setTemplateLikeButtonColor} />
              <CaptionText />
              <div />
            </FeatureFlag>

            <CaptionText text={'\u00A0'} />
            <CaptionText />
            <CaptionText />
            <CaptionText />
            <CaptionText />
            <ButtonTheme color={salesDoc.template.pdfButtonColor}>
              <Button navMinor prefix={DescriptionOutlinedIcon} text={salesDoc.template.pdfButtonText} caps={false} />
            </ButtonTheme>
            <Field gridColumn={'2/5'}>
              <Chip text={'Coming Soon'} prefix={NewIcon} suffix={NewIcon} />
            </Field>
            <div />
          </>
        }
        <CaptionText />
        <CaptionText text={'Enable'} />
        <CaptionText text={'Color'} />
        <CaptionText text={'Button Text'} />
        <CaptionText />
        <ButtonTheme color={salesDoc.template.commentButtonColor}>
          <Button navStandard text={salesDoc.template.commentButtonText} caps={false} />
        </ButtonTheme>
        <Switch checked={salesDoc.template.commentButtonEnabled} onChange={salesDoc.setTemplateCommentButtonEnabled} />
        <ColorPicker color={salesDoc.template.commentButtonColor} onChange={salesDoc.setTemplateCommentButtonColor} />
        <TextInput value={salesDoc.template.commentButtonText} onChange={salesDoc.setTemplateCommentButtonText} />
        <div />

        {!salesDoc.isPresentation() &&
          <>
            <CaptionText />
            <CaptionText text={'Enable'} />
            <CaptionText text={'Color'} />
            <CaptionText text={'Button Text'} />
            <CaptionText />
            <ButtonTheme color={salesDoc.template.acceptButtonColor}>
              <Button navPrimary text={salesDoc.template.acceptButtonText} caps={false} />
            </ButtonTheme>
            <Switch checked={salesDoc.template.acceptButtonEnabled} onChange={salesDoc.setTemplateAcceptButtonEnabled} />
            <ColorPicker color={salesDoc.template.acceptButtonColor} onChange={salesDoc.setTemplateAcceptButtonColor} />
            <TextInput value={salesDoc.template.acceptButtonText} onChange={salesDoc.setTemplateAcceptButtonText} />
            <div />
          </>
        }

        {!salesDoc.isPresentation() &&
          <>
            <CaptionText />
            <CaptionText text={'Enable'} />
            <CaptionText text={'Color'} />
            <CaptionText text={'Button Text'} />
            <CaptionText />
            <ButtonTheme color={salesDoc.template.payButtonColor}>
              <Button navMain text={salesDoc.template.payButtonText} caps={false} />
            </ButtonTheme>
            <Switch checked={hasStripe && salesDoc.template.payButtonEnabled} enabled={hasStripe} onChange={salesDoc.setTemplatePayButtonEnabled} />
            <ColorPicker color={salesDoc.template.payButtonColor} onChange={salesDoc.setTemplatePayButtonColor} />
            <TextInput value={salesDoc.template.payButtonText} onChange={salesDoc.setTemplatePayButtonText} />
            {hasStripe &&
              <div />
            }
            {!hasStripe &&
              <Link to={'/settings/integrations'}>
                Set Up
              </Link>
            }
          </>
        }

        {salesDoc.isPresentation() &&
          <>
            <CaptionText text={'\u00A0'} />
            <CaptionText />
            <CaptionText />
            <CaptionText />
            <CaptionText />
            <ButtonTheme color={salesDoc.template.cartButtonColor}>
              <Button navMain text={salesDoc.template.cartButtonText} caps={false} />
            </ButtonTheme>
            <Field gridColumn={'2/5'}>
              <Chip text={'Coming Soon'} prefix={NewIcon} suffix={NewIcon} />
            </Field>
            <div />
          </>
        }
      </Grid>
    </ToolboxSection>
  );
}

export function DocumentTermsSection() {
  const {salesDoc} = usePageContext();

  return (
    <ToolboxSection className={'document-terms'} heading={'Terms'} hideShow storageKey={'salesdoc|toolbox|general|terms'}>
      <WysiwygEditor value={salesDoc.terms} onChange={salesDoc.setTerms} />
    </ToolboxSection>
  );
}

export function ProductSettingsSection({item}) {
  const {salesDoc} = usePageContext();

  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;

  return (
    <ToolboxSection
      heading={item ? 'Settings' : 'Catalog Product Pricing Settings'}
      innerSep
      hideText={item && 'Hide Settings'}
      showText={item && 'Show Settings'}
      storageKey={'salesdoc|toolbox|settings'}
    >
      <Column className={'settings'}>
        {item &&
          <Field>
            <TaxSelect label='Tax' value={item.tax} onChange={item.setTax} selectDefault />
          </Field>
        }
        {!isPresentation &&
          <DescriptiveRadioGroup
            caption={'Pricing Mode'}
            value={item ? item.priceMode : salesDoc.template.variantPriceMode}
            onChange={item ? item.setPriceMode : salesDoc.setTemplateVariantPriceMode}
          >
            <DescriptiveRadio caption={'Variant Pricing'} value={SalesDocItem.PriceMode.VARIANT}>
              The pricing for each variant is independent.
              Ideal for products where the cost price can be different for certain product variants.
              When an invoice is created, each variant will be invoiced on a separate row.
            </DescriptiveRadio>
            <DescriptiveRadio caption={'Averaged Pricing'} value={SalesDocItem.PriceMode.AVERAGE}>
              The pricing for all product variants will be averaged.
              Ideal for products where the pricing for variants is the same.
              When an invoice is created, all variants will be invoiced on a single line item.
              <Switch
                label={'Show variant breakdown to customer'}
                noWrapLabel
                checked={item ? item.priceMode === SalesDocItem.PriceMode.VARIANT || item.showItem : salesDoc.template.showVariants}
                disabled={item ? item.priceMode === SalesDocItem.PriceMode.VARIANT : salesDoc.template.variantPriceMode === SalesDocItem.PriceMode.VARIANT}
                onChange={item ? item.setShowItem : salesDoc.setTemplateShowVariants}
              />
            </DescriptiveRadio>
          </DescriptiveRadioGroup>
        }
        {isPresentation &&
          <DescriptiveGroup caption={'View Settings'}>
            <DescriptiveSwitch
              caption={'Show Pricing to Customer'}
              checked={item ? item.showPricing : salesDoc.template.showPricing}
              onChange={item ? item.setShowPricing : salesDoc.setTemplateShowPricing}
            >
              Choose to show or hide the pricing table to your customer
            </DescriptiveSwitch>
            <DescriptiveSwitch
              caption={'Show Product Variants to Customer'}
              checked={item ? item.showItem : salesDoc.template.showVariants}
              onChange={item ? item.setShowItem : salesDoc.setTemplateShowVariants}
            >
              Choose to show or hide the Product Options section to your customer
            </DescriptiveSwitch>
          </DescriptiveGroup>
        }
      </Column>
    </ToolboxSection>
  );
}

export function DecorationSettingsSection({item}) {
  const {salesDoc} = usePageContext();

  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;
  const hasVariants = !!item && salesDoc.groupHasProductVariants(item.groupId);
  const showItem = item ? item.showItem : salesDoc.template.showDecorationItems;
  const showItemDisabled = item ? !hasVariants || !item.rollupSellPrice : false;

  const theThis = item ? 'this' : 'the';

  return (
    <ToolboxSection
      className={'salesdoc-toolbox-sections decoration-settings'}
      heading={item ? 'Settings' : 'Decoration Pricing Settings'}
      innerSep
      hideText={item && 'Hide Settings'}
      showText={item && 'Show Settings'}
      storageKey={'salesdoc|toolbox|settings'}
    >
      <Column className={'settings'}>
        {item &&
          <Field>
            <TaxSelect label='Tax' value={item.tax} onChange={item.setTax} selectDefault />
          </Field>
        }
        {isPresentation &&
          <DescriptiveRadioGroup
            caption={'Pricing Mode'}
            value={item ? item.priceMode : salesDoc.template.presentationDecorationPriceMode}
            onChange={item ? item.setPriceMode : salesDoc.setTemplatePresentationDecorationPriceMode}
          >
            <DescriptiveRadio caption={'Quantity Based Pricing'} value={SalesDocItem.PriceMode.QUANTITY_BASED}>
              {'The decoration price changes with quantity. The price will be shown in a grid.'}
            </DescriptiveRadio>
            <DescriptiveRadio caption={'Flat Rate'} value={SalesDocItem.PriceMode.FLAT_RATE}>
              {'The decoration price is the same regardless of the product quantities displayed to the customer.'}
            </DescriptiveRadio>
          </DescriptiveRadioGroup>
        }
        <DescriptiveRadioGroup
          caption={isPresentation ? null : 'Pricing Mode'}
          value={item ? item.rollupSellPrice : salesDoc.template.rollupDecorationSellPrice}
          onChange={item ? item.setRollupSellPrice : salesDoc.setTemplateRollupDecorationSellPrice}
        >
          <DescriptiveRadio caption={'Roll-Up Decoration Pricing'} value={true} disabled={item ? !hasVariants : false}>
            {`This will include the decoration price into all Product(s) within ${theThis} group.`}
            <DescriptiveSwitch
              checked={showItem || showItemDisabled}
              disabled={showItemDisabled}
              onChange={item ? item.setShowItem : salesDoc.setTemplateShowDecorationItems}
              caption={item ? 'Show this Decoration to customer' : 'Show Decorations to the customer'}
              noWrapLabel
              rightUnder
            >
              The decoration will still show while you’re creating/editing the document, but will not be shown to your customer.
            </DescriptiveSwitch>
          </DescriptiveRadio>
          <DescriptiveRadio caption={'Itemise Decoration Pricing'} value={false}>
            {`This will show the decoration pricing to the customer on ${theThis} document and any subsequent invoices.`}
          </DescriptiveRadio>
        </DescriptiveRadioGroup>
      </Column>
    </ToolboxSection>
  );
}

export function AdditionalCostSettingsSection({item}) {
  const {salesDoc} = usePageContext();

  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;
  const hasVariants = !!item && salesDoc.groupHasProductVariants(item.groupId);
  const showItem = item ? item.showItem : salesDoc.template.showAdditionalCostItems;
  const showItemDisabled = item ? !hasVariants || !item.rollupSellPrice : false;

  const theThis = item ? 'this' : 'the';

  return (
    <ToolboxSection
      className={'salesdoc-toolbox-sections additional-cost-settings'}
      heading={item ? 'Settings' : 'Additional Cost Pricing Settings'}
      innerSep
      hideText={item && 'Hide Settings'}
      showText={item && 'Show Settings'}
      storageKey={'salesdoc|toolbox|settings'}
    >
      <Column className={'settings'}>
        {item &&
          <Field>
            <TaxSelect label='Tax' value={item.tax} onChange={item.setTax} selectDefault />
          </Field>
        }
        {isPresentation &&
          <DescriptiveRadioGroup
            caption={'Pricing Mode'}
            value={item ? item.priceMode : salesDoc.template.presentationAdditionalCostPriceMode}
            onChange={item ? item.setPriceMode : salesDoc.setTemplatePresentationAdditionalCostPriceMode}
          >
            <DescriptiveRadio caption={'Quantity Based Pricing'} value={SalesDocItem.PriceMode.QUANTITY_BASED}>
              {'The additional cost price changes with quantity. The price will be shown in a grid.'}
            </DescriptiveRadio>
            <DescriptiveRadio caption={'Flat Rate'} value={SalesDocItem.PriceMode.FLAT_RATE}>
              {'The additional cost price is the same regardless of the product quantities displayed to the customer.'}
            </DescriptiveRadio>
          </DescriptiveRadioGroup>
        }
        <DescriptiveRadioGroup
          caption={isPresentation ? null : 'Pricing Mode'}
          value={item ? item.rollupSellPrice : salesDoc.template.rollupAdditionalCostSellPrice}
          onChange={item ? item.setRollupSellPrice : salesDoc.setTemplateRollupAdditionalCostSellPrice}
        >
          <DescriptiveRadio caption={'Roll-Up Additional Cost Pricing'} value={true} disabled={item ? !hasVariants : false}>
            {`This will include the additional cost price into all Product(s) within ${theThis} group`}
            <DescriptiveSwitch
              checked={showItem || showItemDisabled}
              disabled={showItemDisabled}
              onChange={item ? item.setShowItem : salesDoc.setTemplateShowAdditionalCostItems}
              caption={item ? 'Show this Additional Cost to customer' : 'Show Additional Costs to the customer'}
              noWrapLabel
              rightUnder
            />
            The additional cost will still show while you’re creating/editing the document, but will not be shown to your customer.
          </DescriptiveRadio>
          <DescriptiveRadio caption={'Itemise Additional Cost Pricing'} value={false}>
            {`This will show the additional cost pricing to the customer on ${theThis} document and any subsequent invoices`}
          </DescriptiveRadio>
        </DescriptiveRadioGroup>
      </Column>
    </ToolboxSection>
  );
}

export function RowPricingSection({heading, items, oneOff, fixedQuantity}) {
  const {salesDoc} = usePageContext();
  const firstItem = items[0];

  const decorationPriceBreaks = useMemo(() => (items.reduce((acc, item) => ({
    ...acc,
    [item.decorationId]: item.decoration?.priceBreaks.map((option) => ({value: option.price, text: `${option.quantity} or more`}))
  }), {})), [items]);

  return (
    <ToolboxSection className={'salesdoc-toolbox-sections row-pricing'} heading={heading ?? 'Pricing'} noSep>
      <Table bodyBorder>
        <TableHeading>
          <TableHeadingCell text={'Quantity'} width={75} />
          {firstItem.type === SalesDocItem.Type.VARIANT &&
            <>
              <TableHeadingCell text={'Color'} width={75} />
              <TableHeadingCell text={'Size'} width={75} />
            </>
          }
          <TableHeadingCell text={'Buy Price'} width={75} />
          {!firstItem.isAdditionalCost() &&
            <TableHeadingCell text={'Extra Cost'} width={75} />
          }
          <TableHeadingCell text={'Markup'} width={75} />
          <TableHeadingCell text={'Sell Price'} width={75} />
          <TableHeadingCell width={75}>
            <CaptionText>Unit Price
              {firstItem.isProductVariant() &&
                <InfoTip>
                  The unit price is calculated using the buy price, extra cost, and markup. It also includes any
                  add-ons within the group where the pricing has been &apos;rolled up&apos;. The unit
                  price can be manually overridden to provide a more appealing price for your customer.
                </InfoTip>
              }
              {firstItem.isDecoration() &&
                <InfoTip>
                  The unit price is calculated using the buy price, extra cost, and markup. The unit
                  price can be manually overridden to provide a more appealing price for your customer.
                </InfoTip>
              }
              {firstItem.isAdditionalCost() &&
                <InfoTip>
                  The unit price is calculated using the buy price and markup. The unit price can be manually
                  overridden to provide a more appealing price for your customer.
                </InfoTip>
              }
            </CaptionText>
          </TableHeadingCell>
          <TableHeadingCell text={'SubTotal'} width={90} />
        </TableHeading>
        {items.map((item) => (
          <TableRow key={item.itemId} className={'hover-tools-container'}>
            <TableCell>
              {!item.isAdditionalCost() &&
                <ToolTip focusable tip={item.quantity < item.minQuantity && `This product has a minimum order quantity of ${item.minQuantity}`}>
                  <IntegerInput
                    className={[item.minQuantity != null && item.quantity > 0 && item.quantity < item.minQuantity && 'under-minimum']}
                    value={item.quantity}
                    readOnly={fixedQuantity}
                    onChange={item.setQuantity}
                  />
                </ToolTip>
              }
              {item.isAdditionalCost() &&
                <TextInput
                  value={item.rollupSellPrice ? `${item.quantity} (${item.getAdditionalCostQuantity()} units)` : `${item.quantity}`}
                  readOnly
                />
              }
            </TableCell>
            {item.type === SalesDocItem.Type.VARIANT && !oneOff &&
              <>
                <TableCell>
                  <TextAutoComplete value={item.color} allowAnyValue options={item.product.colors} onChange={item.setColor} />
                </TableCell>
                <TableCell>
                  <TextAutoComplete value={item.size} allowAnyValue options={item.product.sizes} onChange={item.setSize} />
                </TableCell>
              </>
            }
            {item.type === SalesDocItem.Type.VARIANT && oneOff &&
              <>
                <TableCell>
                  <TextInput value={item.color} onChange={item.setColor} />
                </TableCell>
                <TableCell>
                  <TextInput value={item.size} onChange={item.setSize} />
                </TableCell>
              </>
            }
            <TableCell>
              <ToolTip focusable tip={item.isAveragedPrice && !item.buyPriceOverride && 'This product\'s pricing varies depending on its specific variants (color/size). The price displayed here is an average of the prices for all available variants.'}>
                {!firstItem.isDecoration() &&
                  <CurrencyRateInput
                    className={[item.isAveragedPrice && 'averaged-price']}
                    value={item.buyPrice}
                    onChange={item.setBuyPrice}
                    clearable={item.buyPriceOverride && !oneOff}
                    onClear={item.clearBuyPriceOverride}
                  />
                }
                {firstItem.isDecoration() &&
                  <CurrencyRatePicker
                    className={[item.isAveragedPrice && 'averaged-price']}
                    value={item.buyPrice}
                    onChange={item.setBuyPrice}
                    clearable={item.buyPriceOverride && !oneOff}
                    onClear={item.clearBuyPriceOverride}
                    options={decorationPriceBreaks[item.decoration?._id]}
                  />
                }
              </ToolTip>
            </TableCell>
            {!firstItem.isAdditionalCost() &&
              <TableCell>
                <CurrencyRateInput value={item.additionalCost} onChange={item.setAdditionalCost} />
              </TableCell>
            }
            <TableCell>
              <PercentageInput
                value={item.markup}
                onChange={item.setMarkup}
                clearable={item.markupOverride && !oneOff}
                onClear={item.clearMarkupOverride}
              />
            </TableCell>
            <TableCell>
              <CurrencyRateInput value={item.sellPrice} readOnly />
            </TableCell>
            <TableCell>
              <CurrencyRateInput
                value={item.unitPrice}
                onChange={item.setUnitPrice}
                clearable={item.unitPriceOverride}
                onClear={item.clearUnitPriceOverride}
              />
            </TableCell>
            <TableCell>
              <CurrencyInput value={item.subTotal} readOnly />
              <Row className={'hover-tools'}>
                {item.type === SalesDocItem.Type.VARIANT &&
                  <CopyIcon onClick={item.copy} />
                }
                {items.length > 1 &&
                  <DeleteIcon onClick={item.delete} />
                }
              </Row>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {firstItem.type === SalesDocItem.Type.VARIANT &&
        <Row>
          <BodyText>{salesDoc.summary.getVariantQuantity(firstItem.variantId)} Total</BodyText>
          <Button actionPrimary prefix={AddIcon} onClick={items[items.length - 1].addProductVariant}>Add Another Variant</Button>
        </Row>
      }
      {firstItem.isDecoration() &&
        <DecorationAdditionalCostSwitch item={firstItem} oneOff={oneOff} />
      }
    </ToolboxSection>
  );
}

export function GridPricingSection({items, noSep}) {
  const {salesDoc} = usePageContext();
  const [hoverColumn, setHoverColumn] = useState(-1);
  const [addedQuantity, setAddedQuantity] = useState(-1);

  const firstItem = items[0];
  const oneOff = (firstItem.type === SalesDocItem.Type.VARIANT && firstItem.product == null)
    || (firstItem.type === SalesDocItem.Type.DECORATION && firstItem.decoration == null);
  const isFlatRate = firstItem.priceMode === SalesDocItem.PriceMode.FLAT_RATE;

  const handleAddColumn = useCallback((e) => {
    const cell = e.target.closest('td');
    if (cell) {
      const index = [...cell.parentElement.children].indexOf(cell);
      let quantity;
      if (index <= 0) {
        quantity = Math.round(items[0].quantity / 2);
      } else if (index >= items.length) {
        quantity = Math.round(items.at(-1).quantity * 1.5);
      } else {
        quantity = Math.round((items[index].quantity + items[index - 1].quantity) / 2);
      }
      salesDoc.addPresentationGroupColumn(firstItem.groupId, quantity);
      setAddedQuantity(quantity);
    }
  }, [firstItem, items, salesDoc]);

  const handleQuantityKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      const cell = e.target.closest('td');
      if (cell) {
        const index = [...cell.parentElement.children].indexOf(cell);
        if (index >= items.length) {
          handleAddColumn(e);
        }
      }
    }
  }, [handleAddColumn, items?.length]);

  const handleAddedColumnBlur = useCallback(() => {
    setAddedQuantity(undefined);
  }, []);

  const handleDeleteColumn = useCallback((e) => {
    const cell = e.target.closest('td');
    if (cell) {
      const index = [...cell.parentElement.children].indexOf(cell);
      if (index > 0 && index <= items.length) {
        salesDoc.deletePresentationGroupColumn(firstItem.groupId, index - 1);
      }
    }
  }, [firstItem?.groupId, items?.length, salesDoc]);

  const handleMouseOverColumn = useCallback((e) => {
    const cell = e.target.closest('td');
    if (cell) {
      const index = [...cell.parentElement.children].indexOf(cell);
      setHoverColumn(index);
    } else {
      setHoverColumn(-1);
    }
  }, []);

  const handleMouseLeaveColumn = useCallback(() => {
    setHoverColumn(-1);
  }, []);

  return (
    <ToolboxSection
      className={['salesdoc-toolbox-sections grid-pricing', isFlatRate && 'flat-rate', `type-${kebabCase(firstItem.type)}`]}
      heading={'Pricing'}
      noSep={noSep ?? true}
    >
      <Table bodyBorder onMouseOver={handleMouseOverColumn} onMouseLeave={handleMouseLeaveColumn}>
        <TableRow>
          <TableCell text={'Quantity'} className={[hoverColumn === 0 && 'hovered']}>
            <div className={'column-hover-tools'}>
              <AddIcon className={'add-icon'} onClick={handleAddColumn} />
            </div>
          </TableCell>
          {items.map((item, index) => (
            <TableCell key={item.itemId} className={[hoverColumn === index + 1 && 'hovered']}>
              <ToolTip focusable tip={item.quantity < item.minQuantity && `This product has a minimum order quantity of ${item.minQuantity}`}>
                <IntegerInput
                  className={[item.minQuantity > item.quantity && 'under-minimum']}
                  value={item.quantity}
                  onChange={item.setQuantity}
                  autoFocus={item.quantity === addedQuantity}
                  selectOnFocus={item.quantity === addedQuantity}
                  onBlur={handleAddedColumnBlur}
                  onKeyDown={handleQuantityKeyDown}
                />
              </ToolTip>
              <div className={'column-hover-tools'}>
                {items.length > 1 &&
                  <DeleteIcon className={'delete-icon'} onClick={handleDeleteColumn} />
                }
                <AddIcon className={'add-icon'} onClick={handleAddColumn} />
              </div>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell text={'Buy Price'} />
          {items.map((item, index) => (
            <TableCell key={item.itemId}>
              <ToolTip focusable tip={item.isAveragedPrice && !item.buyPriceOverride && 'This product\'s pricing varies depending on its specific variants (color/size). The price displayed here is an average of the prices for all available variants.'}>
                <CurrencyRateInput
                  className={[item.isAveragedPrice && 'averaged-price']}
                  value={item.buyPrice}
                  onChange={item.setBuyPrice}
                  clearable={item.buyPriceOverride}
                  onClear={item.clearBuyPriceOverride}
                  readOnly={isFlatRate && index > 0}
                />
              </ToolTip>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell text={'Extra Cost/Unit'} />
          {items.map((item, index) => (
            <TableCell key={item.itemId}>
              <CurrencyRateInput
                value={item.additionalCost}
                onChange={item.setAdditionalCost}
                readOnly={isFlatRate && index > 0}
              />
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell text={'Markup'} />
          {items.map((item, index) => (
            <TableCell key={item.itemId}>
              <PercentageInput
                value={item.markup}
                onChange={item.setMarkup}
                clearable={item.markupOverride}
                onClear={item.clearMarkupOverride}
                readOnly={isFlatRate && index > 0}
              />
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell text={'Margin'} />
          {items.map((item, index) => (
            <TableCell key={item.itemId}>
              <PercentageInput
                value={marginFromMarkup(item.markup)}
                onChange={item.setMargin}
                clearable={item.markupOverride}
                onClear={item.clearMarkupOverride}
                readOnly={isFlatRate && index > 0}
              />
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell text={'Sell Price'} />
          {items.map((item) => (
            <TableCell key={item.itemId}>
              <CurrencyRateInput value={item.sellPrice} readOnly />
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell text={'Unit Price'} />
          {items.map((item, index) => (
            <TableCell key={item.itemId}>
              <CurrencyRateInput
                value={item.unitPrice}
                onChange={item.setUnitPrice}
                clearable={item.unitPriceOverride}
                onClear={item.clearUnitPriceOverride}
                readOnly={isFlatRate && index > 0 && !item.isAdditionalCost()}
              />
            </TableCell>
          ))}
        </TableRow>
      </Table>
      {firstItem.isDecoration() &&
        <DecorationAdditionalCostSwitch item={firstItem} oneOff={oneOff} />
      }
    </ToolboxSection>
  );
}

function DecorationAdditionalCostSwitch({item, oneOff}) {
  const {setSelection} = usePageContext();

  const handleChangeSetupCostItem = useCallback((e) => {
    if (checkedFromEvent(e)) {
      item.addSetupCostItem(item.decoration?.setupPrice);
    } else {
      item.deleteSetupCostItem();
    }
  }, [item]);

  const handleEditAdditionalCost = useCallback(() => {
    if (item.hasSetupCostItem()) {
      setSelection({section: SalesDocSections.additionalCost, groupId: item.groupId, itemId: item.getSetupCostItem().itemId});
    }
  }, [item, setSelection]);

  return (
    <>
      {!oneOff && !!item.getSetupCost() &&
        <DescriptiveGroup className={'setup-cost'} caption={`This decoration has a setup cost of $${asCurrencyString(item.getSetupCost())}`}>
          <DescriptiveSwitch caption={'Add Setup Cost as an Additional Cost'} checked={item.hasSetupCostItem()} onChange={handleChangeSetupCostItem}>
            This will add an
            <Button actionInline onClick={handleEditAdditionalCost} disabled={!item.hasSetupCostItem()}>Additional Cost</Button>
            into this group for the setup cost.
          </DescriptiveSwitch>
        </DescriptiveGroup>
      }
      {oneOff &&
        <DescriptiveGroup className={'setup-cost'}>
          <DescriptiveSwitch caption={'Add Setup Cost as an Additional Cost'} checked={item.hasSetupCostItem()} onChange={handleChangeSetupCostItem}>
            This will add an
            <Button actionInline onClick={handleEditAdditionalCost} disabled={!item.hasSetupCostItem()}>Additional Cost</Button>
            into this group for the setup cost.
          </DescriptiveSwitch>
        </DescriptiveGroup>
      }
    </>
  );
}

export function RevenueSummary({title, summary, noSep}) {
  const {salesDoc} = usePageContext();
  const hasTypeCosts = summary.totalProductCost != null || summary.totalDecorationCost != null || summary.totalAdditionalCost != null;

  return ((summary ?? null) && !salesDoc.isPresentation() &&
    <ToolboxSection
      heading={title || 'Revenue Summary'}
      hideText={'Hide Revenue Summary'}
      showText={'Show Revenue Summary'}
      storageKey={'salesdoc|toolbox|summary'}
      noSep={noSep}
    >
      <Fields columns={hasTypeCosts ? 8 : 5} className={['revenue-summary', summary.hasRollup && 'has-rollup']} >
        {hasTypeCosts &&
          <>
            <Field>
              <CaptionText text={'Product Cost'} />
              <BodyText dark currency text={asCurrencyString(summary.totalProductCost)} />
            </Field>
            <Field>
              <CaptionText text={'Decoration Cost'} />
              <BodyText dark currency text={asCurrencyString(summary.totalDecorationCost)} />
            </Field>
            <Field>
              <CaptionText text={'Additional Cost'} />
              <BodyText dark currency text={asCurrencyString(summary.totalAdditionalCost)} />
            </Field>
          </>
        }
        {!summary.hasRollup &&
          <Field>
            <CaptionText text={'Total Cost'} />
            <BodyText dark currency text={asCurrencyStringCommaSeparated(summary.totalCost)} />
          </Field>
        }
        {summary.hasRollup &&
          <Field>
            <CaptionText text={'Total Cost'} />
            <BodyText dark currency>
              {asCurrencyStringCommaSeparated(summary.totalCost)}
              <InfoTip
                className={'revenue-summary-tip'}
                tip={'This row shows the revenue summary for this product alone'}
                placement={Placement.Left}
              />
            </BodyText>
            <BodyText dark currency>
              <InfoTip
                className={'revenue-summary-tip'}
                tip={'This row shows the revenue summary for this product including add-ons that are rolled up into the price'}
                placement={Placement.Left}
                arrow
              />
              {asCurrencyStringCommaSeparated(summary.rolledUpCost)}
            </BodyText>
          </Field>
        }
        <Field>
          <CaptionText text={'Total Revenue'} />
          <BodyText dark currency text={asCurrencyStringCommaSeparated(summary.totalRevenue)} />
          {summary.hasRollup &&
            <BodyText dark currency text={asCurrencyStringCommaSeparated(summary.rolledUpRevenue)} />
          }
        </Field>
        <Field>
          <CaptionText text={'Gross Margin'} />
          <BodyText dark text={`${summary.totalRevenue > 0 ? asPercentageString((summary.totalRevenue - summary.totalCost) / summary.totalRevenue * 100) : 0} %`} />
          {summary.hasRollup &&
            <BodyText dark text={`${summary.rolledUpRevenue > 0 ? asPercentageString((summary.rolledUpRevenue - summary.rolledUpCost) / summary.rolledUpRevenue * 100) : 0} %`} />
          }
        </Field>
        <Field>
          <CaptionText text={'Profit Per Unit'} />
          <BodyText dark currency text={asCurrencyString((summary.totalRevenue - summary.totalCost) / Math.max(summary.quantity, 1))} />
          {summary.hasRollup &&
            <BodyText dark currency text={asCurrencyString((summary.rolledUpRevenue - summary.rolledUpCost) / Math.max(summary.quantity, 1))} />
          }
        </Field>
        <Field>
          <CaptionText text={'Profit Total'} />
          <BodyText dark currency text={asCurrencyStringCommaSeparated(summary.totalRevenue - summary.totalCost)} />
          {summary.hasRollup &&
            <BodyText dark currency text={asCurrencyStringCommaSeparated(summary.rolledUpRevenue - summary.rolledUpCost)} />
          }
        </Field>
      </Fields>
    </ToolboxSection>
  );
}

export function CompanySection() {
  const {salesDoc} = usePageContext();
  const {company} = useCompany(salesDoc.companyTradingEntityId ?? salesDoc.customer?.companyTradingEntityId);
  const entityId = salesDoc.companyTradingEntityId ?? salesDoc.customer?.companyTradingEntityId ?? company.companyTradingEntities?.[0]?._id;

  return (
    <ToolboxSection heading={'Company Details'} className={'salesdoc-toolbox-sections company-section'} hideShow={salesDoc.isPresentation()}>
      {company.companyTradingEntities.length > 1 &&
        <Fields columns={1}>
          <div>
            <CaptionText>Your Company:</CaptionText>
            <Select value={entityId} onChange={salesDoc.setCompanyTradingEntityId}>
              {company.companyTradingEntities.map((ent) => (
                <SelectItem key={ent._id} value={ent._id} text={ent.name} />
              ))}
            </Select>
          </div>
        </Fields>
      }
      <Row>
        <Fields leftLabel medium>
          <CaptionText>Company Name:</CaptionText>
          <BodyText>{company.name}</BodyText>

          <CaptionText>Address:</CaptionText>
          <BodyText>{company.address}</BodyText>

          <CaptionText>Phone:</CaptionText>
          <BodyText>{company.phone}</BodyText>

          <CaptionText>Company Email:</CaptionText>
          <BodyText>{company.email}</BodyText>
        </Fields>
        <Column className={'company-logo'}>
          <ImageThumbnail imageUrl={company.logo} />
        </Column>
      </Row>
    </ToolboxSection>
  );
}

export function DocumentDetailsSection() {
  const {salesDoc} = usePageContext();
  const {data: {users = []}} = useListUsers();
  const {user: loggedOnUser} = useUser();

  const handleSetOwner = useCallback((userId) => {
    const user = users.find(({_id}) => _id === userId);
    salesDoc.setOwner(user ? user : loggedOnUser);
  }, [loggedOnUser, salesDoc, users]);

  return (
    <ToolboxSection heading={'Document Details'} className={'salesdoc-toolbox-sections document-details-section'} hideShow={salesDoc.isPresentation()}>
      <Fields columns={3}>
        <div>
          <CaptionText>Creator:</CaptionText>
          <BodyText>{salesDoc.createdBy?.fullName}</BodyText>
        </div>
        <div>
          <CaptionText>Created Date:</CaptionText>
          <BodyText>{formatDateShort(salesDoc.createdAt)}</BodyText>
        </div>
        <div>
          <CaptionText>Document #:</CaptionText>
          <BodyText>{salesDoc.number ?? 'Draft'}</BodyText>
        </div>
        <div>
          <CaptionText>Deadline:</CaptionText>
          <DatePicker value={salesDoc.deadline} onChange={salesDoc.setDeadline} />
          {!salesDoc.isPresentation() &&
            <Row tag={'label'} alignCenter>
              Show to Customer
              <Switch checked={salesDoc.template.showDeadline} onChange={salesDoc.setTemplateShowDeadline} />
            </Row>
          }
        </div>
        <div>
          <CaptionText>Reference:</CaptionText>
          <TextInput value={salesDoc.reference} onChange={salesDoc.setReference} />
          {!salesDoc.isPresentation() &&
            <Row tag={'label'} alignCenter>
              Show to Customer
              <Switch checked={salesDoc.template.showReference} onChange={salesDoc.setTemplateShowReference} />
            </Row>
          }
        </div>
        <div>
          <CaptionText>Owner:</CaptionText>
          <UserSelect value={salesDoc.ownerId} onChange={handleSetOwner} users={users} />
        </div>
      </Fields>
    </ToolboxSection>
  );
}

export function CustomerViewSection() {
  const {contactSearch, salesDoc, setContactSearch, setSelection} = usePageContext();

  const onAddCustomer = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: SalesDocSubSections.addCustomer}));
  }, [setSelection]);

  const onEditCustomer = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: SalesDocSubSections.editCustomer}));
  }, [setSelection]);

  const contact = useMemo(() => ({...salesDoc.contact, customer: salesDoc.customer}), [salesDoc]);
  const contacts = useMemo(() => salesDoc.customer?.contacts?.filter((c) => c.deleted !== true), [salesDoc]);

  return (
    <>
      <ToolboxSection heading={salesDoc.isPresentation() ? 'Customer' : 'Choose Customer'} hideShow>
        <ContactAutoComplete
          search={contactSearch}
          onSearchChange={setContactSearch}
          contact={contact}
          placeholder='Search for an exisiting company/contact, or add a new customer'
          onChange={salesDoc.setContact}
          onAddNewCustomer={onAddCustomer}
        />

      </ToolboxSection>
      {salesDoc.customer &&
        <>
          <ToolboxSection heading={'Details'} hideShow>
            <FieldColumns columns={1}>
              <div>
                <CaptionText>Company:</CaptionText>
                <BodyText>{salesDoc.customer.name}</BodyText>
              </div>
            </FieldColumns>
            <FieldColumns columns={3}>
              <div>
                <CaptionText>Contact Name:</CaptionText>
                <Select value={salesDoc.contactId} onChange={salesDoc.setContactId}>
                  {contacts?.map((_contact) =>
                    <SelectItem key={_contact._id} value={_contact._id}><BodyText>{_contact.fullName}</BodyText></SelectItem>
                  )}
                </Select>
              </div>
              <div>
                <CaptionText>Email:</CaptionText>
                <BodyText>{contact.email}</BodyText>
              </div>
              <div>
                <CaptionText>Contact Number:</CaptionText>
                <BodyText>{contact.phone ?? ''}</BodyText>
              </div>
            </FieldColumns>

            {!salesDoc.isPresentation() &&
              <FieldColumns columns={1}>
                <div>
                  <CaptionText>Billing Address:</CaptionText>
                  <Select value={salesDoc.billingAddress?._id} onChange={salesDoc.setBillingAddress} emptyList={'There are no billing addresses for this customer'}>
                    {salesDoc.customer.addresses?.filter((address) => address.label === 'BILLING').map((address) =>
                      <SelectItem key={address._id} value={address._id}>
                        <BodyText>{formatAddressOneLine(address)}</BodyText>
                      </SelectItem>
                    )}
                  </Select>
                </div>
                <div>
                  <CaptionText>Shipping Address:</CaptionText>
                  <Select value={salesDoc.shippingAddress?._id} onChange={salesDoc.setShippingAddress} emptyList={'There are no shipping addresses for this customer'}>
                    {salesDoc.customer.addresses?.filter((address) => address.label === 'SHIPPING').map((address) =>
                      <SelectItem key={address._id} value={address._id}>
                        <BodyText>{formatAddressOneLine(address)}</BodyText>
                      </SelectItem>
                    )}
                  </Select>
                </div>
              </FieldColumns>
            }
            <FieldColumns columns={1}>
              <Button text='Edit Customer' navPrimary onClick={onEditCustomer} />
            </FieldColumns>

          </ToolboxSection>
          {!salesDoc.isPresentation() &&
            <ToolboxSection heading={'Profile'} hideText={'Hide Profile'} showText={'Show Profile'}>
              <FieldColumns columns={1}>
                <div>
                  <CaptionText>Profile:</CaptionText>
                  <BodyText>{salesDoc.customer.profile}</BodyText>
                </div>
              </FieldColumns>
            </ToolboxSection>
          }
        </>
      }
    </>
  );
}

export function PresentationBlockSection({inSettings, section, image, text, showBlock, setShowBlock, onImageChange, onTextChange}) {
  const {company, salesDoc, uploadCache} = usePageContext();

  const images = uploadCache.files.filter((file) => file.url).map((file) => (file.url));
  if (company.logo ?? company.companyTradingEntities?.[0]?.logo) {
    images.unshift(company.logo ?? company.companyTradingEntities[0]?.logo);
  }
  if (salesDoc.template.headerImage && !images.includes(salesDoc.template.headerImage)) {
    images.unshift(salesDoc.template.headerImage);
  }

  return (
    <ToolboxSection className={'salesdoc-toolbox-sections presentation-block'} heading={`${section} Block`} hideShow>
      <Switch label={`Show ${section} Block`} checked={showBlock} onChange={setShowBlock} />

      <Collapse in={showBlock}>
        <ToolboxSection className={'presentation-image-section'} heading={inSettings ? `Presentation ${section} Image` : 'Image'} innerSep>
          <ImageUploadManager
            acceptPDF
            images={images}
            value={image}
            maxSize={MaxFileSize.Max10Meg}
            onChange={onImageChange}
            onUploadComplete={onImageChange}
            uploadCache={uploadCache}
          />
        </ToolboxSection>

        <ToolboxSection className={'presentation-text-section'} heading={inSettings ? `Presentation ${section} Text` : 'Text'} innerSep>
          <WysiwygProvider>
            <WysiwygSubstitution
              substitutions={nestSubstitutions({salesDoc: salesDocSubstitutions, company: companySubstitutions})}
              columns={{
                'SalesPresentation Details': ['salesDoc'],
                'My Company Details': ['company'],
                'User Details': ['salesDoc.creator', 'salesDoc.owner'],
                'Customer Details': ['salesDoc.contact', 'salesDoc.customer'],
              }}
              fields={inSettings ? {} : {salesDoc, company}}
              insertMissingFieldKeys
              value={text}
              onChange={onTextChange}
            />
          </WysiwygProvider>
        </ToolboxSection>
      </Collapse>
    </ToolboxSection>
  );
}

export function PresentationColorsAndSizesSection({item, oneOff}) {
  return (
    <>
      {!oneOff &&
        <ToolboxSection className={'salesdoc-toolbox-sections product-variants-section'} heading={'Product Variants'} noSep>
          <Row alignBaseline>
            <BodyText>
              Colors Available
            </BodyText>
            <Button className={'select-all'} actionPrimary text={'Select all'} noWrap onClick={() => item.setColors(item.product.colors)} />
            <BodyText>|</BodyText>
            <Button className={'deselect-all'} actionPrimary text={'Deselect all'} noWrap onClick={() => item.setColors([])} />
          </Row>
          <ChipList
            chips={item.product.colors}
            selected={item.colors}
            onChangeSelected={item.setColors}
            addButtonText={'Add Color'}
          />
          <BodyText>
            Sizes Available
            <Button className={'select-all'} actionPrimary text={'Select all'} onClick={() => item.setSizes(item.product.sizes)} />|
            <Button className={'deselect-all'} actionPrimary text={'Deselect all'} onClick={() => item.setSizes([])} />
          </BodyText>
          <ChipList
            chips={item.product.sizes}
            selected={item.sizes}
            onChangeSelected={item.setSizes}
            addButtonText={'Add Size'}
            sortFunction={sortSizes}
          />
        </ToolboxSection>
      }
      {oneOff &&
        <ToolboxSection className={'product-variants-section'} heading={'Product Variants'} noSep>
          <BodyText>Colors</BodyText>
          <ChipList chips={[]} selected={item.colors} onChangeSelected={item.setColors} addButtonText={'Add Color'} />
          <BodyText>Sizes</BodyText>
          <ChipList chips={[]} selected={item.sizes} onChangeSelected={item.setSizes} addButtonText={'Add Size'} sortFunction={sortSizes} />
        </ToolboxSection>
      }
    </>
  );
}

export function ImageSection({item}) {
  const {salesDoc, selection, setSelection, uploadCache} = usePageContext();
  const isPresentation = salesDoc.isPresentation();

  const variantImages = useMemo(() => {
    if (isPresentation) {
      return (item.product?.colors ?? []).reduce((acc, color) => {
        if (item.product?.namedImages[color] && item.colors?.includes(color) && !acc[color]) {
          acc[color] = item.product.namedImages[color];
        }
        return acc;
      }, {});
    } else {
      return salesDoc.getVariantItems(selection.variantId).reduce((acc, variantItem) => {
        if (variantItem.product?.namedImages[variantItem.color]) {
          acc[variantItem.color] = variantItem.product.namedImages[variantItem.color];
        }
        return acc;
      }, {});
    }
  }, [item.colors, item.product?.namedImages, item.product?.colors, isPresentation, salesDoc, selection.variantId]);
  const images = uniq([
    ...(!isPresentation && item?.image ? [item.image] : []),
    ...Object.values(variantImages).map((image) => image),
    ...(isPresentation && item?.images ? item.images : []),
    ...uploadCache.files.filter((file) => file.url).map((file) => (file.url)),
  ]);

  const handleViewAllImages = useCallback(() => {
    setSelection((prevSelection) => ({...prevSelection, section: SalesDocSections.productImage}));
  }, [setSelection]);

  const handleSelectAllImages = useCallback(() => {
    item.setImages(images);
  }, [images, item]);

  const handleDeselectAllImages = useCallback(() => {
    item.setImages([]);
  }, [item]);

  return (
    <ToolboxSection
      className={'salesdoc-toolbox-sections images-section'}
      heading={!isPresentation && 'Image'}
      noSep={!isPresentation}
      innerSep={isPresentation}
    >
      {isPresentation &&
        <Row alignBaseline>
          <HeadingText text={'Images'} fit />
          <Button className={'select-all'} actionPrimary text={'Select all'} noWrap onClick={handleSelectAllImages} />
          <BodyText>|</BodyText>
          <Button className={'deselect-all'} actionPrimary text={'Deselect all'} noWrap onClick={handleDeselectAllImages} />
        </Row>
      }
      <ImageUploadManager
        acceptPDF
        images={images}
        value={isPresentation ? item.images : item.image}
        maxSize={MaxFileSize.Max10Meg}
        multiSelect={isPresentation}
        onChange={isPresentation ? item.setImages : item.setImage}
        onUploadComplete={isPresentation ? item.setImages : item.setImage}
        uploadCache={uploadCache}
      >
        {item.product &&
          <Button
            className={'view-all-button'}
            actionPrimary
            onClick={handleViewAllImages}
            text={`View All Images (${Object.keys(item.product.namedImages).length ?? 0})`} />
        }
      </ImageUploadManager>
    </ToolboxSection>
  );
}
